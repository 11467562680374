import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import DesktopAccessDisabled from '@mui/icons-material/DesktopAccessDisabled';
import AccessTime from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import Typography from '@mui/material/Typography';
import { PaginationV2 } from '@shared/components/pagination-v2/pagination-v2';
import { SkeletonTableRows, MembershipStatus, FilterForm, roleTitleKeys } from 'features/account-settings';
import EmptyResultBox from 'assets/images/Empty_results_box.svg';
import * as Styles from 'features/account-settings/manage-users/styles';
import { usePaginationV2 } from '@shared/components/pagination-v2/hooks/use-pagination-v2.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { searchMemberships as searchMembershipsRequest } from 'api/userManagement';
import { useSnackbar } from '@chr/eds-react';

export const DataTable = ({ onEditClick }: { onEditClick: (userId: string) => void }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [pageNumber, itemsPerPage, setPageNumber, setItemsPerPage] = usePaginationV2(1, 10);
  
  const [response, setResponse] = useState<SearchMembershipResponse>({ totalPossible: 0, memberships: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [searchCriteria, setSearchCriteria] = useState<{ searchType: MembershipSearchType; searchText: string } | undefined>();

  const activeMembership = useSelector(state => state.userManagement?.activeMembership);
  const organizationId = activeMembership?.organizationId;

  const statusMapper = useMemo(() => ({
      [MembershipStatus.ACTIVE]: {
        displayText: 'Active',
        color: 'rgba(0, 92, 5, 1)',
        icon: <CheckCircleOutline sx={Styles.StatusIcon} />,
      },
      [MembershipStatus.DISABLED]: {
        displayText: 'Suspended',
        color: 'rgba(181, 77, 8, 1)',
        icon: <DesktopAccessDisabled sx={Styles.StatusIcon} />,
      },
      [MembershipStatus.PENDING]: {
        displayText: 'Invitation Sent',
        color: 'rgba(0, 113, 199, 1)',
        icon: <AccessTime sx={Styles.StatusIcon} />,
      },
  }), [])

  const searchMemberships = useCallback(() => {
    setHasError(false);
    setIsLoading(true);

    const request: SearchMembershipRequest = {
      pageSize: itemsPerPage,
      pageNumber,
      searchCriteria: {
        organizationId,
        ...(searchCriteria ?? {}),
      },
    };
    searchMembershipsRequest(request)
      .then(setResponse)
      .catch(() => {
        setHasError(true);
        snackbar.enqueueSnackbar({
          id: 'search-memberships-failure',
          message: t('FALLBACK_SOMETHING_WENT_WRONG'),
          severity: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  }, [itemsPerPage, pageNumber, organizationId, searchCriteria]);

  useEffect(() => {
    searchMemberships();
  }, [pageNumber, itemsPerPage, searchCriteria, organizationId]);

  return (
    <>
      <FilterForm
        isLoading={isLoading}
        onSearch={criteria => {
          setSearchCriteria(criteria);
          setPageNumber(1);
        }}
        onClear={() => {
          setSearchCriteria(undefined)
        }}
      />
      <Paper sx={Styles.TablePaper}>
        <TableContainer>
          <Table sx={Styles.TableComponent}>
            <TableHead sx={Styles.TableHead}>
              <TableRow>
                <TableCell sx={Styles.TableHeading}>{t('ACTION')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('STATUS')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('NAME')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('EMAIL')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('ROLE')}</TableCell>
                <TableCell sx={Styles.TableHeading}>{t('COMPANY')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <SkeletonTableRows />
              ) : hasError ? (
                <TableRow>
                <TableCell colSpan={7}>
                  <Box sx={Styles.NoDataContainer}>
                  <img src={EmptyResultBox} alt="No data" style={{ marginBottom: '20px' }} />
                  <Typography variant="subtitle100">{t('MY_USERS_ERROR_FETCHING')}</Typography>
                  <Button variant="outlined" startIcon={<ReplayIcon />} onClick={searchMemberships}>
                    {t('TRY_AGAIN')}
                  </Button>
                  </Box>
                </TableCell>
                </TableRow>
              ) : !response.memberships.length ? (
                <TableRow>
                <TableCell colSpan={7}>
                  <Box sx={Styles.NoDataContainer}>
                  <img src={EmptyResultBox} alt="No data" style={{ marginBottom: '20px' }} />
                  <Typography variant="subtitle100">{t('MY_USERS_NO_RESULT_TITLE')}</Typography>
                  <Typography variant="body100">{t('MY_USERS_NO_RESULT_BODY')}</Typography>
                  </Box>
                </TableCell>
                </TableRow>
              ) : (
                response.memberships.map(({ userId, attributes, roles, status }) => {
                const { displayText, color, icon } = statusMapper[status] ?? {};

                return (
                  <TableRow key={userId} sx={Styles.TableRow}>
                  <TableCell sx={Styles.TableCell} component="th" scope="row">
                    <Button variant="outlined" startIcon={<EditIcon />} onClick={() => onEditClick(userId)}>
                    {t('EDIT')}
                    </Button>
                  </TableCell>
                  <TableCell sx={{ ...Styles.TableCell, color }}>
                    {icon} {displayText}
                  </TableCell>
                  <TableCell sx={Styles.TableCell}>{`${attributes.firstName} ${attributes.lastName}`}</TableCell>
                  <TableCell sx={Styles.TableCell}>{attributes.email}</TableCell>
                  <TableCell sx={Styles.TableCell}>{roles.length > 1 ? t('MULTIPLE') : t(roleTitleKeys[roles[0]])}</TableCell>
                  <TableCell sx={Styles.TableCell}>{attributes.companyName}</TableCell>
                  </TableRow>
                );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: isLoading ? 'flex' : 'none', justifyContent: 'space-between', padding: '16px' }}>
          <Skeleton animation="wave" variant="rounded" width="200px" />
          <Skeleton animation="wave" variant="rounded" width="200px" />
        </Box>
        <Box sx={{...Styles.PaginationContainer, display: !isLoading && Boolean(response.memberships.length) ? 'block' : 'none'}}>
          <PaginationV2
            totalItems={response.totalPossible ?? 0}
            pageNumber={pageNumber}
            itemsPerPage={itemsPerPage}
            setPageNumber={setPageNumber}
            setItemsPerPage={setItemsPerPage}
            closeDetails={() => { }}
            isBanner={false}
            tab="table-pagination"
          />
        </Box>
      </Paper>
    </>
  );
};
