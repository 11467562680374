import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEmail, isValidName, isValidPhone, FilterValueFieldName, SearchByDisplayValue } from 'features/account-settings';

export const SearchType = Object.freeze({
 Email: 1,
 Name: 2,
 Phone: 3,
 Status: 4,
});

export function useFilterOptions() {
 const { t } = useTranslation();
 const [filterOptions, setFilterOptions] = useState<MembershipFilterOption[]>([
  {
   displayValue: SearchByDisplayValue.EMAIL,
   searchType: SearchType.Email,
   isSelected: true,
   value: '',
   placeholder: 'example@mail.com',
   name: FilterValueFieldName.Email,
   validator: isValidEmail,
   errorMessage: '',
  },
  {
   displayValue: SearchByDisplayValue.NAME,
   searchType: SearchType.Name,
   isSelected: false,
   value: '',
   placeholder: t('SEARCH_BY_NAME_PLACEHOLDER'),
   name: FilterValueFieldName.Name,
   validator: isValidName,
   errorMessage: '',
  },
  {
   displayValue: SearchByDisplayValue.PHONE_NUMBER,
   searchType: SearchType.Phone,
   isSelected: false,
   value: '',
   name: FilterValueFieldName.Phone,
   validator: isValidPhone,
   errorMessage: '',
  },
  {
   displayValue: SearchByDisplayValue.STATUS,
   searchType: SearchType.Status,
   isSelected: false,
   value: 'Active',
   name: FilterValueFieldName.Status,
   options: [
    { key: 'Active', displayValue: 'Active' },
    { key: 'Disabled', displayValue: 'Suspended' },
    { key: 'Pending', displayValue: 'Invitation Sent' },
   ],
  },
 ]);

 return { filterOptions, setFilterOptions };
}
